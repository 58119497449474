<template>
	<section id="content" class="grid-container">
		<div class="masonry-css">
			<div class="masonry-css-item" v-for="project in projects">
				<router-link :to="$route.path + '/' + project.uid">
					<prismic-image :field="project.data.teaser"/>
				</router-link>
				<prismic-rich-text :field="project.data.intro"/>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Projects',
	data() {
		return {
			projects: []
		};
	},
	created() {
		this.$prismic.client.query([
				this.$prismic.Predicates.at('document.type', 'test'),
				this.$prismic.Predicates.at('document.tags', this.$route.meta.prismicTags)
			], {
				fetch: ['test.title', 'test.intro', 'test.teaser'],
				pageSize: 250,
				orderings: '[my.test.sortdate desc]'
			}
		).then((doc) => {
			this.projects = doc.results;
		})
	}
}
</script>
