<template>
	<section id="content" class="grid-container">
		<p>Loading the Prismic's Preview...</p>
	</section>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'Preview',
	beforeCreate () {
		const { token, documentId } = this.$route.query

		this.$prismic.client.getPreviewResolver(token, documentId).resolve(this.$prismic.linkResolver, '/')
			.then((url) => {
				this.$router.push(url);
			})
		}
	}
</script>
