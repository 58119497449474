<template>
	<section id="content" class="grid-container">
		<div class="grid-x grid-padding-x align-top">
			<div class="cell large-4 medium-6">
				<p>Und <strong>Kontakt</strong></p>
				<img src="/images/und/limmatquai62_grossmuenster.jpg">
					<p>
						Schmauder Und Gmbh<br>
						Limmatquai 62<br>
						8001 Zürich<br>
						044 261 01 57<br>
						<a href="mailto:info@schmauderund.ch">info@schmauderund.ch</a>
						<br><br>
					</p>
				<img src="/images/und/lageplan.svg">
			</div>
			<div class="cell large-4 medium-6 memberships">
				<p>Und <strong>Netzwerk</strong></p>
				<p>Wir verfügen über ein umfangreiches Netzwerk an Experten, mit welchen wir projektorientiert
					zusammenarbeiten.<br>
					<br>
					<br>
					Und <strong>Mitglied</strong> bei:<br>
					<a href="https://museums.ch/service/icom/" target="_blank">ICOM – International Council of Museums</a><br>
				</p>
			</div>
			<div class="cell large-4 medium-6">
				<p>Und <strong>Kunden</strong></p>

				<div class="client-list">
					<prismic-rich-text v-for="c in clients" v-bind:key="c.id" :field="c.client" :htmlSerializer="externalLinkSerializer"/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import externalLinkSerializer from '@/prismic/external-link-serializer.js';

export default {
	name: 'Und',
	data() {
		return {
			externalLinkSerializer,
			clients: []
		};
	},
	created() {
		this.$prismic.client.getSingle('clients')
			.then((doc) => {
				this.clients = doc.data.listentry;
			})
	}
}
</script>
