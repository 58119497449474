import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Wir from '@/views/Wir.vue'
import Und from '@/views/Und.vue'
import Projects from '@/views/Projects.vue'
import Project from '@/views/Project.vue'
import Preview from '@/views/Preview.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'neugierig, voller Ideen und reich an Erfahrung',
			description: 'Das Atelier für Konzeption, Szenografie und Grafik Design übersetzt Inhalte in unvermutete räumliche Geschichten, inszeniert mit überraschender und lustvoller Gestaltung und setzt sorgfältig um, sowohl analog als auch digital.',
		}
	},
	{
		path: '/wir',
		name: 'Wir',
		component: Wir,
		meta: {
			title: 'Team',
			description: 'Teamtext vielleicht mit Namen.',
		}
	},
	{
		path: '/und',
		name: 'Und',
		component: Und,
		meta: {
			title: 'Über uns',
			description: 'Kontakt, Lage, Netzwerk, Mitgliedschaften und Kunden von Schmauder Und.',
		}
	},
	{
		path: '/grafik',
		name: 'Grafik',
		component: Projects,
		meta: {
			title: 'Grafik',
			description: 'Das Atelier für Konzeption, Szenografie und Grafik Design übersetzt Inhalte in unvermutete räumliche Geschichten, inszeniert mit überraschender und lustvoller Gestaltung und setzt sorgfältig um, sowohl analog als auch digital.',
			prismicTags: ['Grafik']
		}
	},
	{
		path: '/szenografie',
		name: 'Szenografie',
		component: Projects,
		meta: {
			title: 'Szenografie',
			description: 'Das Atelier für Konzeption, Szenografie und Grafik Design übersetzt Inhalte in unvermutete räumliche Geschichten, inszeniert mit überraschender und lustvoller Gestaltung und setzt sorgfältig um, sowohl analog als auch digital.',
			prismicTags: ['Szenografie']
		}
	},
	{
		path: '/konzept',
		name: 'Konzept',
		component: Projects,
		meta: {
			title: 'Konzept',
			description: 'Das Atelier für Konzeption, Szenografie und Grafik Design übersetzt Inhalte in unvermutete räumliche Geschichten, inszeniert mit überraschender und lustvoller Gestaltung und setzt sorgfältig um, sowohl analog als auch digital.',
			prismicTags: ['Konzept']
		}
	},
	{
		path: '/preview',
		name: 'Preview',
		component: Preview,
		meta: {}
	},
	{
		path: '/:topic/:id',
		name: 'Project',
		component: Project
	},
]

const router = new VueRouter({
	mode: 'history',
	routes,
	linkActiveClass: 'active',
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			if (savedPosition) {
				setTimeout(() => { resolve(savedPosition) }, 1000);
			} else {
				setTimeout(() => { resolve({ x: 0, y: 0 }) }, 10);
			}
		});
	}
})

router.afterEach((to, from) => {
	// Use next tick to handle router history correctly
	// see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
	Vue.nextTick(() => {
		const title = "Schmauder Und" + (to.meta.title ? ' - ' + to.meta.title : '');
		const descr = (to.meta.description ? to.meta.description : '')
		document.title = title;
		document.querySelector('meta[name="description"]').setAttribute('content', descr);
		document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);
		document.querySelector('meta[name="twitter:description"]').setAttribute('content', descr);
		document.querySelector('meta[property="og:title"]').setAttribute('content', title);
		document.querySelector('meta[property="og:description"]').setAttribute('content', descr);
		document.querySelector('meta[property="og:url"]').setAttribute('content', location.origin + to.fullPath);
	});
});

export default router
