<template>
	<div class="home">
		<div class="grid-container" id="content">
			<div class="grid-x grid-padding-x">
				<div class="large-9 medium-12 cell">
					<prismic-rich-text :field="intro"/>
				</div>
			</div>
		</div>

		<section id="blog" class="grid-container grid-x">
			<div class="large-9 medium-12 cell container" @click="checkRouterLink">
				<div v-for="blog in blogs" :class="['row', blog.data.posttype]">
					<prismic-rich-text v-if="blog.data.posttype=='img-right'" :field="blog.data.blogtext" class="byline" :htmlSerializer="localLinkSerializer" />
					<div class="image"><prismic-image :field="blog.data.blogimg"/></div>
					<prismic-rich-text v-if="blog.data.posttype=='img-left'" :field="blog.data.blogtext" class="byline" :htmlSerializer="localLinkSerializer" />
					<prismic-rich-text v-if="blog.data.posttype=='large'" :field="blog.data.blogtext" class="largetext" :htmlSerializer="localLinkSerializer" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import localLinkSerializer from '@/prismic/local-link-serializer.js';

export default {
	name: 'Home',
	data() {
		return {
			localLinkSerializer,
			intro: [],
			blogs: []
		};
	},
	methods: {
		checkRouterLink: function(event) {
			console.log(event.target);
			if (event.target.matches('a[data-router-link]')) {
				event.preventDefault();
				this.$router.push(event.target.pathname);
			}
		}
	},
	created() {
		this.$prismic.client.getSingle('home')
			.then((doc) => this.intro = doc.data.intro);

		this.$prismic.client.query([this.$prismic.Predicates.at('document.type', 'blog')], {
				fetch: ['blog.posttype', 'blog.blogimg', 'blog.blogtext'],
				pageSize: 250,
				orderings: '[my.blog.sortdate desc]'
			}
		).then((doc) => this.blogs = doc.results);
	}
}

</script>
