<template>
	<div v-if="images && images.length" class="grid-x grid-padding-x align-bottom">
		<div v-for="img in images" :class="['cell', 'medium-'+img.imgtype]">
			<prismic-image :field="img.img"/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Gallery',
		props: ['images']
	}
</script>
