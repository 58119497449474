import prismicDOM from 'prismic-dom';
import linkResolver from '@/prismic/link-resolver';

const Elements = prismicDOM.RichText.Elements;

export default function(type, element, content, children) {
	if (type === Elements.hyperlink) {
		const url = prismicDOM.Link.url(element.data, linkResolver);
		return `<a href="${url}" target="_blank">${content}</a>`;
	}
	return null;
};
