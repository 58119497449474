<template>
	<section id="content" class="grid-container">
		<div class="grid-x grid-padding-x align-top">
			<div class="cell large-4 medium-6">
				<img src="/images/wir/claudia_schmauder.jpg">
				<p><strong>Claudia Schmauder</strong><br>Konzeption, Gestaltung</p>
				<ul class="byline">
					<li>dipl. Visuelle Gestaltung, HfGZ</li>
					<li>CAS Signaletik, HKB</li>
					<li>Studium MAS<br>Applied History, UZH</li>
				</ul>
				<p><a href="mailto:claudia.schmauder@schmauderund.ch">Kontakt</a></p>
			</div>
			<div class="cell large-4 medium-6">
				<img src="/images/wir/julia_castillo.jpg">
				<p><strong>Julia Castillo</strong><br>Grafikdesign</p>
				<ul class="byline">
					<li>MA Graphic Communication Design, Central Saint Martins, University of the Arts London</li>
					<li>CAS Type Design and Typography, ZHdK</li>
				</ul>
				<p><a href="mailto:julia.castillo@schmauderund.ch">Kontakt</a></p>
			</div>
			<div class="cell large-4 medium-6">
				<img src="/images/wir/laura_murbach.jpg">
				<p><strong>Laura Murbach</strong><br>
					Szenografie, Bauleitung</p>
				<ul class="byline">
					<li>dipl. Designerin FH, ZHdK</li>
				</ul>
				<p><a href="mailto:laura.murbach@schmauderund.ch">Kontakt</a></p>
			</div>
			<div class="cell large-4 medium-6 last">
				<p><strong>Anja Taravella</strong><br>Junior Szenografie</p>
				<ul class="byline">
					<li>Szenografie, HGK Basel</li>
					<li>Grafikerin EFZ</li>
				</ul>
				<p><a href="mailto:anja.taravella@schmauderund.ch">Kontakt</a></p>
			</div>
			<div class="cell large-4 medium-6 last">
				<p><strong>Ehemalige Mitarbeiter- und Praktikantinnen</strong></p>
				<ul class="byline">
					<li>Marie-Thérèse Vu</li>
					<li>Valerie Keusch</li>
					<li>Mara Spühler</li>
					<li>Isabelle Dörig</li>
					<li>Antea Tönett</li>
				</ul>
			</div>
		</div>

		<Gallery :images="gallery" />
	</section>
</template>

<script>
import Gallery from '@/components/Gallery.vue';

export default {
	name: 'Wir',
	components: { Gallery },
	data() {
		return {
			gallery: []
		};
	},
	created() {
		this.$prismic.client.getSingle('bureau')
			.then((doc) => {
				this.gallery = doc.data.bgallery;
			})
	}
}
</script>
