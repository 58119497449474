<template>
	<div id="app">
		<Header :class="{home: ($route.path === '/')}"/>
		<transition name="fade" mode="out-in">
			<router-view :key="$route.path"/>
		</transition>
	</div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
	components: { Header }
}
</script>


<style lang="scss">
	@import "@/scss/app.scss";

	.fade-enter-active { transition: opacity 1s; }
	.fade-leave-active { transition: opacity .5s; }
	.fade-enter, .fade-leave-to { opacity: 0; }
</style>
