<template>
	<div>
		<header class="grid-container">
			<div id="mobile-nav">
				<div :class="['title-bar', {active: toggle}]" data-hide-for="medium">
					<div class="title-bar-title"><router-link to="/" exact>Schmauder Und</router-link></div>
					<div :class="['hamburger-container', {active: toggle}]" @click="toggleOverlay">
						<span class="top"></span>
						<span class="middle"></span>
						<span class="bottom"></span>
					</div>
				</div>
			</div>
			<div id="large-nav">
				<div class="top-bar">
					<div class="top-bar-left">
						<router-link to="/" exact>Schmauder Und</router-link>
					</div>
					<div class="top-bar-right">
						<ul class="menu">
							<li><router-link to="/szenografie">Szenografie</router-link></li>
							<li><router-link to="/grafik">Grafik</router-link></li>
							<li><router-link to="/konzept">Konzept</router-link></li>
							<li><router-link to="/wir">Wir</router-link></li>
							<li><router-link to="/und">Und</router-link></li>
						</ul>
					</div>
				</div>
			</div>
		</header>
		<div :class="['overlay', {open: toggle}]" @click="toggleOverlay">
			<nav class="overlay-menu">
				<ul>
					<li><router-link to="/szenografie">Szenografie</router-link></li>
					<li><router-link to="/grafik">Grafik</router-link></li>
					<li><router-link to="/konzept">Konzept</router-link></li>
					<li><router-link to="/wir">Wir</router-link></li>
					<li><router-link to="/und">Und</router-link></li>
				</ul>
			</nav>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Header',
		data: function() {
			return {
				toggle: false
			}
		},
		methods: {
			toggleOverlay: function() {
				this.toggle = !this.toggle;
			}
		}
	}
</script>
