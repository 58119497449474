import prismicDOM from 'prismic-dom';
import linkResolver from '@/prismic/link-resolver';

const Elements = prismicDOM.RichText.Elements;

export default function(type, element, content, children) {
	if (type === Elements.hyperlink) {
		const url = prismicDOM.Link.url(element.data, linkResolver);
		if (element.data.link_type === 'Document') {
			return `<a data-router-link href="${url}">${children.join('')}</a>`
		}
	}
	return null;
};
