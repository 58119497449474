export default function(doc) {

	if (doc.type === 'home') return '/';
	if (doc.type === 'bureau') return '/wir';
	if (doc.type === 'clients') return '/und';

	// Projects
	if (doc.type === 'test') {
		const tag = doc.tags && doc.tags.length ? doc.tags[0].toLowerCase() : 'grafik';
		const uid = doc.uid;
		return `/${tag}/${uid}`;
	}

	// Default to the root
	return '/';
};
