<template>
	<section id="content" class="grid-container">
		
		<Gallery :images="p.introimgs" />

		<div class="grid-x grid-padding-x align-bottom">
			<div class="cell medium-4" v-if="p.intropos == 'img-left'"><prismic-image :field="p.introimg"/></div>
			<div class="cell medium-8">
				<prismic-rich-text :field="p.introtext"/>
				<prismic-rich-text v-if="p.introtextsmall" :field="p.introtextsmall" class="byline"/>
			</div>
			<div class="cell medium-4" v-if="p.intropos=='img-right'"><prismic-image :field="p.introimg"/></div>
		</div>

		<Gallery :images="p.gallery1" />

		<div v-if="p.addedtext" class="grid-x grid-padding-x align-bottom">
			<div class="cell medium-4" v-if="p.addedpos=='img-left'"><prismic-image :field="p.addedimg"/></div>
			<div class="cell medium-8">
				<prismic-rich-text :field="p.addedtext"/>
				<prismic-rich-text v-if="p.addedtextsmall" :field="p.addedtextsmall" class="byline"/>
			</div>
			<div class="cell medium-4" v-if="p.addedpos=='img-right'"><prismic-image :field="p.addedimg"/></div>
		</div>

		<Gallery :images="p.gallery2" />
		
		<div v-if="['Grafik', 'Szenografie', 'Konzept'].indexOf(prevRoute.name) >= 0" id="backlink" @click="$router.go(-1)"><i>←</i></div>
	</section>
	
</template>

<script>
	import Vue from 'vue';
	import Gallery from '@/components/Gallery.vue';

	export default {
		name: 'Project',
		components: { Gallery },
		data() {
			return {
				p: {
					introimgs: [],
					introimg: {},
					introtext: [],
					introtextsmall: [],
					intropos: '',
					gallery1: [],
					gallery2: [],
				},
				prevRoute: null
			};
		},
		beforeRouteEnter(to, from, next) { 
			next(vm => {
				vm.prevRoute = from
			})
		},
		created() {
			
			this.$prismic.client.getByUID('test', this.$route.params.id)
				.then((doc) => {
					this.p = doc.data;

					Vue.nextTick(() => {
						document.title = "Schmauder Und - " + this.p.title[0].text;
						document.querySelector('meta[name="description"]').setAttribute('content', this.p.intro[0].text);
						document.querySelector('meta[name="twitter:title"]').setAttribute('content', this.p.title[0].text);
						document.querySelector('meta[name="twitter:description"]').setAttribute('content', this.p.intro[0].text);
						document.querySelector('meta[property="og:title"]').setAttribute('content', this.p.title[0].text);
						document.querySelector('meta[property="og:description"]').setAttribute('content', this.p.intro[0].text);
					});					
				})
		}
	}
</script>
